<template>
  <div>
    <SiteMapDiscription />
  </div>
</template>

<script>
import SiteMapDiscription from '@/components/parts/SiteMapDiscription.vue'

export default {
  components: {
    SiteMapDiscription,
  },
} 
</script>

<style scoped lang="scss">

</style>