import kyouData from '@/data/kyouData'
import daidaikichiData from '@/data/daidaikichiData'
import { kanjiDataMapStroke } from '@/logic/common/kanjiDataMapStroke'

export const generateName2Func = (gyakusansu, choice) => {
  const nameList = []

  for(let index = 0; index < daidaikichiData.length; index++) {
    if(gyakusansu < 0) {
      return false
    }

    if(daidaikichiData[index] - gyakusansu > 0) {
      const checkData = daidaikichiData[index] - gyakusansu
      const pattern = checkData - 1

      for (let i = 1; pattern >= i; i++ ) {
        const numStroke_1 = checkData - i
        const numStroke_2 = checkData - numStroke_1
        let flg = false

        kyouData.map((kyouDataItem) => {
          if(kyouDataItem === numStroke_1 || kyouDataItem === numStroke_2) {
            flg = true
          }
        })

        if (!flg) {
          const kanjiData_1 = kanjiDataMapStroke(numStroke_1)
          const kanjiData_2 = kanjiDataMapStroke(numStroke_2)

          const nameList2Data = []
          kanjiData_1.map((kanjiData1Item) => {
            kanjiData_2.map((kanjiData2Item) => {
              if(kanjiData1Item.kanji === choice || kanjiData2Item.kanji === choice) {
                nameList2Data.push(`${kanjiData1Item.kanji}${kanjiData2Item.kanji}`)
              }
            })
          })
          nameList.push(...nameList2Data)
        }
      }
    }
  }

  return nameList
}