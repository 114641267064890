<template>
  <div>
    <p>
      このサイトは姓名判断と名字から良い名前の画数になる漢字を乱雑に表示させるためのサイトです。<br>
      漢字の羅列を作るだけです。良い名前を生成するサイトではありません。<br>
      漢字の羅列を見て、それを名前にするのは自由です。命名する際のアシストになれば幸いです。<br>
    </p>
    <p>
      このサイトは開運することを目的としておりません。<br>
      このサイトは自己満足で作成されています。<br>
      結果に対してアドバイスなどしておりません。<br>
    </p>
  </div>
</template>

<script>
export default {
  name: 'SiteDiscription',
}
</script>

<style scoped>

</style>
