import kyouData from '@/data/kyouData'
import daidaikichiData from '@/data/daidaikichiData'
import { kanjiDataMapStroke } from '@/logic/common/kanjiDataMapStroke'

export const generateName1Func = (gyakusansu) => {
  const nameList = []

  for(let index = 0; index < daidaikichiData.length; index++) {
    if(gyakusansu < 0) {
      return false
    }

    if(daidaikichiData[index] - gyakusansu > 0) {
      const checkData = daidaikichiData[index] - gyakusansu
      const pattern = checkData

      for (let i = 1; pattern >= i; i++ ) {
        const numStroke_1 = checkData - i
        let flg = false

        kyouData.map((kyouDataItem) => {
          if(kyouDataItem === numStroke_1) {
            flg = true
          }
        })

        if (!flg) {
          const kanjiData_1 = kanjiDataMapStroke(numStroke_1)

          const nameList1Data = []
          kanjiData_1.map((kanjiData1Item) => {
            nameList1Data.push(`${kanjiData1Item.kanji}`)
          })
          nameList.push(...nameList1Data)
        }
      }
    }
  }

  return nameList
}