<template>
  <div>
    {{ kakuName }}: {{ kakusuu }} {{ kakuString }} {{ kakuData }}
  </div>
</template>

<script>
import kanjiData from '@/data/kanjiData.json'
import { calcFunc } from '@/logic/calcFunc.js'

export default {
  name: 'SoukakuComponents',
  props: ['kakuData', 'kakuName'],
  data(){
    return{
      data: '',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'X-RapidAPI-Key': '41ffda6542msh290edeb257f2e33p169644jsnc0196839e241',
      },
      kakuNumArray: [],
      kakusuu: 0,
      kakuString: '',
      kanjiDataJson: kanjiData,
    }
  },
  methods: {
    clickName() {
      this.kakuNumArray = []
      const kakuDataArray = this.kakuData.split('')

      kakuDataArray.map((kakuDataArrayItem, index) => {
        kanjiData.map((kanjiDataItem) => {
          if(kakuDataArrayItem === kanjiDataItem.kanji) {
            this.kakuNumArray.push({id: index, stroke: Number(kanjiDataItem.stroke)})
          }
        })
      })
    },
  },
  watch: {
    kakuNumArray: {
      deep: true,
      handler: function() {
        const kakuGoukei = this.kakuNumArray.reduce((kakuGoukei, obj) => kakuGoukei + obj.stroke, 0);
        const result = calcFunc(kakuGoukei)

        this.kakusuu = kakuGoukei
        this.kakuString = result
      },
    }
  }
} 
</script>

<style scoped lang="scss">
</style>