<template>
  <li v-for="(showNameStringItem, index) in showNameString" :key="index" @click="accordionToggle($event); setIndex(index)">
    <p>{{ showNameStringItem }}</p>
    <p class="accordion__item" :class="{ 'accordion__item--open': isOpened && this.num === index }">
      <span class="accordion__detail" :class="{ 'accordion__detail--show': isOpened && this.num === index }">
        {{ checkData.sou }}<br />
        {{ checkData.ten }}<br />
        {{ checkData.chi }}<br />
        {{ checkData.jin }}<br />
        {{ checkData.gai }}<br />
        {{ checkData.shigoto }}<br />
        {{ checkData.katei }}
      </span>
    </p>
  </li>
</template>

<script>
import { checkName } from '@/logic/checkName'

export default {
  props: ['showNameString', 'seiData'],
  data(){
    return{
      showFlg: false,
      checkData: [],
      isOpened: false,
      num: 0,
    }
  },
  methods: {
    accordionToggle(event) {
      const checkData = checkName(this.seiData, event.target.innerText)
      this.checkData = checkData
    },
    setIndex(index) {
      this.isOpened = false
      this.num = index
    }
  },
  watch: {
    num: {
      deep: true,
      handler: function() {
        this.isOpened = true;
      }
    }
  }
} 
</script>

<style scoped lang="scss">
.accordion__item {
  height: 0px;
  display: block;
  transition: .2s;
  &--open {
    height: 170px;
    border-bottom: 1px solid #ccc
  }
}

.accordion__detail {
  display: none;
  &--show {
    display: block;
  }
}
</style>