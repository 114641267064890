import kanjiData from '@/data/kanjiData.json'
import { calcFunc } from '@/logic/calcFunc.js'

export const checkName = (seiData, meiData) => {
  const sou = seiData + meiData
  const ten = seiData
  const chi = meiData
  const jin = seiData.slice(-1)[0] + meiData.slice(0,1)
  const gai = sou.length <= 2 ? sou : sou.replace(jin, '')
  const katei = seiData.slice(-1)[0] + meiData
  const shigoto = seiData + meiData.slice(0,1)

  const nameData = [ sou, ten, chi, jin, gai, katei, shigoto]

  const resultNameArray = nameData.map((item) => {
    const dataArray = []
    const nameArray = item.split('')
    nameArray.map((kakuDataArrayItem, index) => {
      kanjiData.map((kanjiDataItem) => {
        if(kakuDataArrayItem === kanjiDataItem.kanji) {
          dataArray.push({id: index, stroke: Number(kanjiDataItem.stroke)})
        }
      })
    })
    const kakuGoukei = dataArray.reduce((kakuGoukei, obj) => kakuGoukei + obj.stroke, 0);
    const result = calcFunc(kakuGoukei)
    return result
  })

  const resultData = {
    sou: '総格: ' + resultNameArray[0],
    ten: '天格: ' + resultNameArray[1],
    chi: '地格: ' + resultNameArray[2],
    jin: '人格: ' + resultNameArray[3],
    gai: '外格: ' + resultNameArray[4],
    katei: '仕事運: ' + resultNameArray[5],
    shigoto: '家庭運: ' + resultNameArray[6],
  }

  return resultData
}