<template>
  <div>
    <SiteDiscription />
  </div>
</template>

<script>
import SiteDiscription from '@/components/parts/SiteDiscription.vue'

export default {
  components: {
    SiteDiscription,
  },
} 
</script>

<style scoped lang="scss">

</style>