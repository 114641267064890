import kanjiData from '@/data/kanjiData.json'

export const kanjiDataMapStroke = (num) => {
  const kanjiItem = []
  kanjiData.map((kanjiDataItem) => {
    if (kanjiDataItem.stroke === num ) {
      kanjiItem.push(kanjiDataItem)
    }
  })

  return kanjiItem
}