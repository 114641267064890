<template>
  <div>
    <label>姓: <input type="text" name="sei" id="" v-model="name.sei" /></label><br>
    <label>名前に入れたい1文字: <input type="text" name="choice" id="" maxlength="1" v-model="propsData.choice" /></label><br>
    <button v-on:click="clickName">ボタン</button>

    <Gyakusan v-bind:seiData="propsData.ten" v-bind:choice="propsData.choice" ref="child" />
  </div>
</template>

<script>
import Gyakusan from '@/components/parts/GyakusanName.vue'

export default {
  components: {
    Gyakusan,
  },
  data () {
    return{
      data: [ { "kanji": { "character": "一", "stroke": 1 }, "radical": { "character": "⼀", "stroke": 1, "order": 1 } } ],
      numbers: 1,
      name: {
        sei: '',
      },
      propsData: {
        ten: '',
        choice: '',
      },
    }
  },
  methods: {
    clickName() {
      this.$refs.child.clickName()
    }
  },
  watch: {
    name: {
      deep : true,
      handler: function() {
        this.propsData.ten = this.name.sei
      }
    }
  }
} 
</script>

<style scoped lang="scss">
</style>