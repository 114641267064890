<template>
  <div>
    選定: {{ choice }}
  </div>
  <ul class="list">
    <GyakusanItem v-bind:showNameString="showNameString1" v-bind:seiData="name.sei" />
  </ul>
  <ul class="list">
    <GyakusanItem v-bind:showNameString="showNameString2" v-bind:seiData="name.sei" />
  </ul>
  <ul class="list">
    <GyakusanItem v-bind:showNameString="showNameString3" v-bind:seiData="name.sei" />
  </ul>
</template>

<script>
import kanjiData from '@/data/kanjiData.json'
import { generateName1Func } from '@/logic/generateName1'
import { generateName2Func } from '@/logic/generateName2'
import { generateName3Func } from '@/logic/generateName3'
import GyakusanItem from '@/components/parts/GyakusanNameItem.vue'

export default {
  props: ['seiData', 'choice'],
  components: {
    GyakusanItem,
  },
  data(){
    return{
      data: '',
      name: {
        sei: '',
        choice: this.choice
      },
      gyakusanNumArray: [],
      gyakusankakusuu: 0,
      gyakusanString: '',
      showNameString1: [],
      showNameString2: [],
      showNameString3: [],
      kanjiDataJson: kanjiData,
      checkData: []
    }
  },
  methods: {
    clickName() {
      this.gyakusanNumArray = []
      const seiArray = this.seiData.split('')
      const getStokeArray = []

      seiArray.map((seiArrayItem, index) => {
        kanjiData.map((kanjiDataItem) => {
          if(seiArrayItem === kanjiDataItem.kanji) {
            getStokeArray.push({id: index, stroke: Number(kanjiDataItem.stroke)})
          }
        })
      })
      this.gyakusanNumArray = getStokeArray
      this.name.sei = this.seiData
    },
  },
  watch: {
    gyakusanNumArray: {
      deep : true,
      handler: function() {
        const tenkakuTotal = this.gyakusanNumArray.reduce((tenkakuTotal, obj) => tenkakuTotal + obj.stroke, 0);
        this.gyakusansuu = tenkakuTotal

        // 1文字
        this.showNameString1 = generateName1Func(tenkakuTotal)

        // 2文字
        this.showNameString2 = generateName2Func(tenkakuTotal, this.choice)

        // ３文字
        this.showNameString3 = generateName3Func(tenkakuTotal, this.choice)
      },
    }
  }
} 
</script>

<style scoped lang="scss">
.list {
  list-style-type: none; 
  li {
    width: 100%;
  }
}
</style>