import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import AboutView from '@/views/AboutView.vue'
import GyakusanView from '@/views/GyakusanView.vue'
import AishoView from '@/views/AishoView.vue'
import SiteMapView from '@/views/SiteMapView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView
  },
  {
    path: '/naduke',
    name: 'naduke',
    component: GyakusanView
  },
  {
    path: '/aisho',
    name: 'asiho',
    component: AishoView
  },
  {
    path: '/sitemap',
    name: 'sitemap',
    component: SiteMapView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router