<template>
  <div>
    <h1>サイトマップ</h1>
    <ul>
      <li>
        <h2>トップページ</h2>
        <p>サイトのトップページ</p>
        <router-link to="/">Link</router-link>
      </li>
      <li>
        <h2>名付けページ</h2>
        <p>名前の候補を組み合わせられるだけ全部出すページ</p>
        <router-link to="/naduke">Link</router-link>
      </li>
      <li>
        <h2>相性診断ページ</h2>
        <p>自分と相手の名前から相性を診断するページ</p>
        <router-link to="/aisho">Link</router-link>
      </li>
      <li>
        <h2>サイトの説明</h2>
        <p>サイトポリシーだったり、目指していることを明示するページ</p>
        <router-link to="/about">Link</router-link>
      </li>
      <li>
        <h2>サイトマップ</h2>
        <p>サイト内各ページの説明とリンクの一覧を表示するページ</p>
        <router-link to="/sitemap">Link</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'SiteMap',
}
</script>

<style scoped>

</style>
    