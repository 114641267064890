<template>
  <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/naduke">Naduke</router-link> |
    <router-link to="/aisho">Aisho</router-link> |
    <router-link to="/about">About</router-link> |
    <router-link to="/sitemap">Sitemap</router-link>
  </nav>
</template>

<script>
export default {
  props: {
    siteName: String,
    notesMsg: String
  }
}
</script>

<style scoped>
nav {
  max-width: 960px;
  margin: auto;
}
ul {
  display: flex;
  list-style-type: none;
}
</style>
