<template>
  <ul>
    <li>sign in</li>
    <li>sign up</li>
  </ul>
</template>

<script>
export default {
  props: {
    siteName: String,
    notesMsg: String
  }
}
</script>

<style scoped>
ul {
  display: flex;
  list-style-type: none;
}
</style>
