<template>
  <div>
    <h1>相性診断</h1>
    <p>
      相性を見るページ準備中<br>
    </p>
  </div>
</template>

<script>
export default {
  name: 'AishoDiscription',
}
</script>

<style scoped>

</style>
