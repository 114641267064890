<template>
  <div>
    <AishoDiscription />
  </div>
</template>

<script>
import AishoDiscription from '@/components/parts/AishoDiscription.vue'

export default {
  components: {
    AishoDiscription,
  },
} 
</script>

<style scoped lang="scss">

</style>