<template>
  <div>
    <input type="text" name="sei" id="" v-model="name.sei" />
    <input type="text" name="mei" id="" v-model="name.mei" />
    <button v-on:click="clickKakusuu">ボタン</button>

    <KakuCommon v-bind:kakuData="propsData.sou" v-bind:kakuName="'総格'" ref="child1" />
    <KakuCommon v-bind:kakuData="propsData.ten" v-bind:kakuName="'天格'" ref="child2" />
    <KakuCommon v-bind:kakuData="propsData.chi" v-bind:kakuName="'地格'" ref="child3" />
    <KakuCommon v-bind:kakuData="propsData.jin" v-bind:kakuName="'人格'" ref="child4" />
    <KakuCommon v-bind:kakuData="propsData.gai" v-bind:kakuName="'外格'" ref="child5" />
    <KakuCommon v-bind:kakuData="propsData.shigoto" v-bind:kakuName="'仕事運'" ref="child6" />
    <KakuCommon v-bind:kakuData="propsData.katei" v-bind:kakuName="'家庭運'" ref="child7" />

  </div>
</template>

<script>
import KakuCommon from '@/components/parts/KakuCommonComponents.vue'

export default {
  components: {
    KakuCommon,
  },
  data () {
    return{
      data: [ { "kanji": { "character": "一", "stroke": 1 }, "radical": { "character": "⼀", "stroke": 1, "order": 1 } } ],
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'X-RapidAPI-Key': '41ffda6542msh290edeb257f2e33p169644jsnc0196839e241',
      },
      numbers: 1,
      name: {
        sei: '',
        mei: '',
      },
      propsData: {
        sou: '',
        ten: '',
        jin: '',
        chi: '',
        gai: '',
        katei: '',
        shigoto: '',
        choice_1: '',
      },
    }
  },
  methods: {
    clickKakusuu() {
      this.$refs.child1.clickName()
      this.$refs.child2.clickName()
      this.$refs.child3.clickName()
      this.$refs.child4.clickName()
      this.$refs.child5.clickName()
      this.$refs.child6.clickName()
      this.$refs.child7.clickName()
    },
  },
  watch: {
    name: {
      deep : true,
      handler: function() {
        this.propsData.sou = this.name.sei + this.name.mei
        this.propsData.ten = this.name.sei
        this.propsData.chi = this.name.mei
        this.propsData.jin = this.name.sei.slice(-1)[0] + this.name.mei.slice(0,1)
        this.propsData.gai = this.propsData.sou.length <= 2 ? this.propsData.sou : this.propsData.sou.replace(this.propsData.jin, '')
        this.propsData.katei = this.name.sei.slice(-1)[0] + this.name.mei
        this.propsData.shigoto = this.name.sei + this.name.mei.slice(0,1)
      }
    }
  }
} 
</script>

<style scoped lang="scss">

</style>