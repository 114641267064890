<template>
  <header>
    <div class="header__wrap">
      <h1>
        <img alt="Vue logo" src="@/assets/logo.png">
        <div class="logo__wrap">
          <strong>{{ siteName }}</strong>
          <span>{{ notesMsg }}</span>
      </div>
      </h1>

      <MembeRegistration />
    </div>

    <GlobalNav />
  </header>
</template>

<script>
import GlobalNav from './GlobalNavi.vue'
import MembeRegistration from './MembeRegistration.vue'

export default {
  name: 'HeaderPrimary',
  components: {
    GlobalNav,
    MembeRegistration,
  },
  props: {
    siteName: String,
    notesMsg: String
  }
}
</script>

<style scoped>
header {
  background-color: #ffffff;
  width: 100%;
}
h1 {
  display: flex;
}
img {
  width: 60px;
  height: 60px;
}
strong {
  font-size: 26px;
  font-weight: 700;
  line-height: 1;
}
span {
  font-size: 14px;
  font-weight: 100;
}
.header__wrap {
  max-width: 960px;
  margin: 0 auto;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.logo__wrap {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 2px;
}
</style>
