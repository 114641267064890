<template>
  <HeaderPrimary siteName="名前のやつ" notesMsg="姓名判断 + 名前ジェネレーター"/>
  <main>
    <router-view />
  </main>
</template>

<script>
import HeaderPrimary from './components/layout/HeaderPrimary.vue'

export default {
  name: 'App',
  components: {
    HeaderPrimary,
  }
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #8cd4b4;
}

main {
  max-width: 960px;
  margin: 0 auto;
  background-color: #ffffff;
  min-height: calc(100vh - 90px);
}
</style>
