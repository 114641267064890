import kyouData from '@/data/kyouData'
import daidaikichiData from '@/data/daidaikichiData'
import { kanjiDataMapStroke } from '@/logic/common/kanjiDataMapStroke'

export const generateName3Func = (gyakusansu, choice) => {
  const nameList = []

  for(let index = 0; index < daidaikichiData.length; index++) {
    if(gyakusansu < 0) {
      return false
    }

    if(daidaikichiData[index] - gyakusansu > 0) {
      const checkData = daidaikichiData[index] - gyakusansu
      const pattern = checkData - 2

      for (let i = 1; pattern >= i; i++ ) {
        for(let j = 1; i >= j; j++) {
          const numStroke_1 = checkData - 1 - i
          const numStroke_2 = checkData - numStroke_1 - j
          const numStroke_3 = checkData - numStroke_1 - numStroke_2
          let flg = false

          kyouData.map((kyouDataItem) => {
            if(kyouDataItem === numStroke_1 || kyouDataItem === numStroke_2 || kyouDataItem === numStroke_3) {
              flg = true
            }
          })

          if (!flg) {
            const kanjiData_1 = kanjiDataMapStroke(numStroke_1)
            const kanjiData_2 = kanjiDataMapStroke(numStroke_2)
            const kanjiData_3 = kanjiDataMapStroke(numStroke_3)

            const nameList3Data = []
            kanjiData_1.map((kanjiData1Item) => {
              kanjiData_2.map((kanjiData2Item) => {
                kanjiData_3.map((kanjiData3Item) => {
                  if(kanjiData1Item.kanji === choice || kanjiData2Item.kanji === choice || kanjiData3Item.kanji === choice) {
                    nameList3Data.push(`${kanjiData1Item.kanji}${kanjiData2Item.kanji}${kanjiData3Item.kanji}`)
                  }
                })
              })
            })
            nameList.push(...nameList3Data)
          }
        }
      }
    }
  }

  return nameList
}